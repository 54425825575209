import React, { useState } from "react";
import { FaCheck, FaInfoCircle, FaTimes } from "react-icons/fa";
import { PRICING_INFO, PRODUCTS, PRODUCT_KEYS } from "./constants/pricing";
import { useStoreActions, useStoreState } from "../../hooks";
import FullManagementEnquiry from "../../components/Popups/FullManagementEnquiry/FullManagementEnquiry";
import Confirm from "../../components/Popups/Confirm";
import "./pricingTable.css";

type ProductChosen = {
  product: typeof PRODUCTS[0];
  name?: string;
  price?: number;
  isMonthly: boolean;
  includeMentoring?: boolean;
  priceId: string;
};

type PricingTableProps = {
  currentPlan?: string;
  intervalForChangePlan: string;
  noActivePlan?: boolean;
  banner?: string;
  showTrialInfo?: boolean;
  showDiscountInfo?: boolean;
  onSignUp?: (product: ProductChosen) => void;
  onChangePlan?: (product: ProductChosen) => void;
};

const PricingTable = (props: PricingTableProps) => {
  // TODO: where to get data for current plan?
  const currentPlan = PRODUCT_KEYS.portalAndQip;
  const [mentoring, setMentoring] = useState("");
  const [isMonthly, setIsMonthly] = useState(true);

  const [showEnquiryModal, setShowEnquiryModal] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);

  const services = useStoreState((state) => state.userStore.servicesDetails);

  const withTrial = false; // this will only be true if redirect from WP subscription

  const numberWithCommas = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const calcPrice = (
    withMentoring: boolean,
    isMonthly: boolean,
    key: string
  ): number => {
    if (isMonthly) {
      switch (key) {
        case "qip":
          return withMentoring ? 197 : 97;
        case "portal":
          return withMentoring ? 197 : 97;
        case "portalAndQip":
          return withMentoring ? 294 : 194;
        default:
          return 997;
      }
    } else {
      switch (key) {
        case "qip":
          return withMentoring ? 1997 : 997;
        case "portal":
          return withMentoring ? 1997 : 997;
        case "portalAndQip":
          return withMentoring ? 2994 : 1994;
        default:
          return 9970;
      }
    }
  };
  const actionLabel = (
    productTitle: string,
    currentPlan?: string,
    mentoringSelected?: boolean
  ) => {
    let cp = currentPlan;

    if (currentPlan?.toLocaleLowerCase().includes("mentoring")) {
      cp = cp?.split("+")[0].trim();
    }

    if (
      productTitle === cp ||
      (productTitle.includes("&") && cp?.includes("&"))
    ) {
      if (currentPlan && currentPlan.toLowerCase().includes("mentoring")) {
        if (mentoringSelected) {
          if (isMonthly === (props.intervalForChangePlan === "month")) {
            return "CURRENT PLAN";
          } else {
            return "UPDATE PLAN";
          }
        } else {
          return "UPDATE PLAN";
        }
      } else {
        if (mentoringSelected) {
          return "UPDATE PLAN";
        } else {
          if (isMonthly === (props.intervalForChangePlan === "month")) {
            return "CURRENT PLAN";
          } else {
            return "UPDATE PLAN";
          }
        }
      }
    }
    return "UPDATE PLAN";
  };

  const actionPlan = (
    productTitle: string,
    currentPlan?: string,
    mentoringSelected?: boolean
  ) => {
    let cp = currentPlan;

    if (currentPlan?.toLocaleLowerCase().includes("mentoring")) {
      cp = cp?.split("+")[0].trim();
    }

    if (
      productTitle === cp ||
      (productTitle.includes("&") && cp?.includes("&"))
    ) {
      if (currentPlan && currentPlan.toLowerCase().includes("mentoring")) {
        if (
          mentoringSelected &&
          isMonthly === (props.intervalForChangePlan === "month")
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (mentoringSelected) {
          return false;
        } else {
          return isMonthly === (props.intervalForChangePlan === "month");
        }
      }
    }
    return false;
  };

  return (
    <div className="pricingTable">
      {showThankYouModal && (
        <Confirm
          isOpen={showThankYouModal}
          text={`Thank you! Your message has been sent, and we’ll be in touch soon.`}
          confirmText={`CLOSE`}
          onConfirm={() => setShowThankYouModal(false)}
        />
      )}
      {showEnquiryModal && (
        <FullManagementEnquiry
          isOpen={showEnquiryModal}
          onClose={() => {
            setShowEnquiryModal(false);
          }}
          onConfirm={() => {
            setShowEnquiryModal(false);
            setShowThankYouModal(true);
          }}
        />
      )}
      <div className="pricingPlan">
        <span>Monthly</span>
        <label className="switch">
          <input
            type="checkbox"
            checked={isMonthly}
            onChange={() => setIsMonthly(!isMonthly)}
          />
          <span className="slider round"></span>
        </label>
        <span>Yearly</span>
      </div>
      {props.banner && (
        <div className="pricingPlanPromo">
          <span>{props.banner}</span>
        </div>
      )}
      <div className="pricingTableList">
        <div className="pricingTableInfo">
          <ul>
            <li></li>
            {PRICING_INFO.map((info) => (
              <li key={info.title}>
                <div className="feature">
                  <span className="infoText">{info.title}</span>
                  {info.tooltip && (
                    <span className="tooltip" data-tip={info.tooltip}>
                      <FaInfoCircle size={18} />
                    </span>
                  )}
                </div>
              </li>
            ))}
            <li>
              <div className="feature">
                <span className="infoText">ADD MONTHLY MENTORING SESSIONS</span>
                <span
                  className="tooltip"
                  data-tip="Our Monthly Mentoring sessions are designed to mentor and inspire leaders about how to better plan their time, deal with interruptions, profile their team, always work with goals, increase positive impacts driven by critical reflection and centralise all systems. Ultimately, our monthly mentoring sessions are designed to help leaders learn how to effectively motivate and drive their teams achieve best outcomes."
                >
                  <FaInfoCircle size={18} />
                </span>
              </div>
            </li>
          </ul>
        </div>
        {PRODUCTS.map((product) => (
          <React.Fragment key={product.key}>
            <div
              className={`productContainer ${
                props.noActivePlan
                  ? product.key === PRODUCT_KEYS.portalAndQip
                    ? "active"
                    : ""
                  : ""
              }  ${
                !props.noActivePlan
                  ? (isMonthly === (props.intervalForChangePlan === "month") &&
                      product.title ===
                        props.currentPlan?.split("+")[0].trim()) ||
                    (product.title.includes("&") &&
                      props.currentPlan?.includes("&"))
                    ? "active"
                    : ""
                  : ""
              } `}
            >
              {/* {product.key === currentPlan && (
              )} */}
              {props.noActivePlan
                ? product.key === PRODUCT_KEYS.portalAndQip && (
                    <span className="productTopText">Most popular</span>
                  )
                : product.title === props.currentPlan &&
                  isMonthly === (props.intervalForChangePlan === "month") && (
                    <span className="productTopText">Your current plan</span>
                  )}
              <ul>
                <li>
                  <div>
                    <span className="appName">{product.title}</span>
                    <span className="planPrice flex flex-col justify-center items-center">
                      {!isMonthly && product.key === "qip" ? (
                        mentoring === product.key ? (
                          <span className={`saveAmount`}>Save $367</span>
                        ) : (
                          <span className={`saveAmount`}>Save $167</span>
                        )
                      ) : (
                        <> </>
                      )}
                      {!isMonthly && product.key === "portal" ? (
                        mentoring === product.key ? (
                          <span className={`saveAmount`}>Save $367</span>
                        ) : (
                          <span className={`saveAmount`}>Save $167</span>
                        )
                      ) : (
                        <> </>
                      )}
                      {!isMonthly && product.key === "portalAndQip" ? (
                        mentoring === product.key ? (
                          <span className={`saveAmount`}>Save $551</span>
                        ) : (
                          <span className={`saveAmount`}>Save $334</span>
                        )
                      ) : (
                        <> </>
                      )}
                      {!isMonthly && product.key === "fullManagement" && (
                        <span className={`saveAmount`}>Save $1,994</span>
                      )}
                      <span className={"priceTag "}>
                        {" "}
                        {isMonthly ? (
                          <>
                            $
                            {numberWithCommas(
                              calcPrice(
                                product.key === mentoring,
                                isMonthly,
                                product.key
                              )
                            )}{" "}
                            <span>monthly</span>
                          </>
                        ) : (
                          <>
                            $
                            {numberWithCommas(
                              calcPrice(
                                product.key === mentoring,
                                isMonthly,
                                product.key
                              )
                            )}
                            <span>yearly</span>
                          </>
                        )}
                      </span>
                    </span>
                  </div>
                </li>
                {PRICING_INFO.map((pricing) => (
                  <li
                    className={`feature-item ${
                      pricing.features[product.key] ? "mobile-visible" : ""
                    }`}
                    key={pricing.title}
                  >
                    {pricing.features[product.key] ? (
                      <>
                        <FaCheck size={18} className="fa-check" />
                        <div className="feature">
                          <span className="infoText">{pricing.title}</span>
                          {pricing.tooltip && (
                            <span
                              className="tooltip"
                              data-tip={pricing.tooltip}
                            >
                              <FaInfoCircle size={18} />
                            </span>
                          )}
                        </div>
                      </>
                    ) : (
                      <FaTimes size={18} className="fa-times" />
                    )}
                  </li>
                ))}
                {product.id !== 4 ? (
                  <li>
                    <label className="checkboxContainer">
                      <input
                        type="radio"
                        checked={mentoring === product.key}
                        onClick={() => {
                          if (mentoring === product.key) {
                            setMentoring("");
                          } else {
                            setMentoring(product.key);
                          }
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <span>
                      + $100<span className={`text-base`}> p/m</span>
                      <span className="data mobile-visible">
                        Add Monthly mentoring sessions
                      </span>
                    </span>
                  </li>
                ) : (
                  <li style={{ height: "78px" }}>
                    <span>Included</span>
                  </li>
                )}
              </ul>
              {console.log("props pt ", props)}
              {props.onSignUp && (
                <a
                  className={`cursor-pointer `}
                  onClick={() => {
                    if (product.id !== 4) {
                      props.onSignUp?.({
                        product: {
                          ...product,
                          title:
                            product.title +
                            (mentoring === product.key ? " + Mentoring" : ""),
                        },

                        includeMentoring: mentoring === product.key,
                        isMonthly,
                        price: calcPrice(
                          product.key === mentoring,
                          isMonthly,
                          product.key
                        ),
                        priceId:
                          product.price_ids[
                            mentoring === product.key
                              ? "withMentoring"
                              : "withNoMentoring"
                          ][withTrial ? "withTrial" : "withNoTrial"][
                            isMonthly ? "monthly" : "yearly"
                          ],
                      });
                    }
                    if (product.id === 4) {
                      setShowEnquiryModal(true);
                    }
                  }}
                >
                  {product.id === 4 ? `CONTACT US` : `SIGN UP`}
                </a>
              )}

              {props.onChangePlan && (
                <a
                  className={`${
                    // product.title === props.currentPlan
                    actionPlan(
                      product.title,
                      props.currentPlan,
                      mentoring === product.key
                    )
                      ? "current"
                      : " cursor-pointer "
                  } `}
                  onClick={() => {
                    if (
                      product.id !== 4 &&
                      !actionPlan(
                        product.title,
                        props.currentPlan,
                        mentoring === product.key
                      )
                    ) {
                      props.onChangePlan?.({
                        product: {
                          ...product,
                          title:
                            product.title +
                            (mentoring === product.key ? " + Mentoring" : ""),
                        },
                        isMonthly,
                        price: calcPrice(
                          product.key === mentoring,
                          isMonthly,
                          product.key
                        ),
                        priceId:
                          product.price_ids[
                            mentoring === product.key
                              ? "withMentoring"
                              : "withNoMentoring"
                          ][withTrial ? "withTrial" : "withNoTrial"][
                            isMonthly ? "monthly" : "yearly"
                          ],
                      });
                    }
                    if (product.id === 4) {
                      setShowEnquiryModal(true);
                    }
                  }}
                >
                  {product.id === 4
                    ? `CONTACT US`
                    : actionLabel(
                        product.title,
                        props.currentPlan,
                        mentoring === product.key
                      )}
                </a>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
      {props.showTrialInfo && (
        <div className="freeTrial">
          <div className="freeTrialRow">
            <span>
              <FaInfoCircle size={18} />
            </span>
            <p className="pr-10">
              Your first 30 days are free! Payments start after this period
              Discounts apply for services with multiple centres.{" "}
              <a href="mailto:support@holisticmanagement.net.au">
                Contact our team
              </a>{" "}
              for details Get 2 months FREE with an annual plan
            </p>
          </div>
        </div>
      )}
      {props.showDiscountInfo && (
        <div className="freeTrial">
          <div className="freeTrialRow">
            <span>
              <FaInfoCircle size={18} />
            </span>
            <p className="pr-10">
              Discounts apply for services with multiple centres.{" "}
              <a href="mailto:support@holisticmanagement.net.au">
                Contact our team
              </a>{" "}
              for details. Benefit from a discount with an annual plan
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingTable;
