import React, { useEffect, useState } from "react";
import { FaExternalLinkSquareAlt, FaInfoCircle } from "react-icons/fa";
import home_banner_logged_in from "../../static/images/home_banner_logged_In.jpg";
import styles from "./HomePage.module.css";
import holistic_qip_img from "../../static/images/holistic_qip.png";
import holistic_management_portal from "../../static/images/holistic_management_portal.png";
import { redirect } from "../../helpers/redirect";
import { getUserLoggedIn } from "../../services/auth";
import { useStoreActions, useStoreState } from "../../hooks";
import { useHistory } from "react-router-dom";
import { get as pmGet } from "../../api/paymentMethod";
import { isEmpty } from "lodash";
import ChangePlanRequest from "../../components/Popups/ChangePlanRequest/ChangePlanRequest";
import Confirm from "../../components/Popups/Confirm";
import { LANDINGPAGE } from "../../enum/action";

const HomePage: React.FC = (): JSX.Element => {
  const authUser = getUserLoggedIn();

  const history = useHistory();

  const [hasPm, setHasPm] = useState(false);

  const [gettingPMLoading, setGettingPMLoading] = useState(false);

  const [showChangePlanRequest, setShowChangePlanRequest] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);

  const services = useStoreState((state) => state.userStore.servicesDetails);

  const redirectToFailedPayment = services?.some((service) => {
    return service.redirectPaymentFailedPage;
  });

  const canAccessQIP = services?.some((service) => {
    return service.canAccessQIP || service.isOnTrial;
  });

  const canAccessPortal = services?.some((service) => {
    return service.canAccessPortal || service.isOnTrial;
  });

  const onRedirect = (app: string) => {
    redirect(app, authUser.id, 0, authUser.stripe_id);
  };

  useEffect(() => {
    const fetchPM = async () => {
      setGettingPMLoading(true);
      const response = await pmGet(authUser.id);
      setHasPm(!isEmpty(response));
      setGettingPMLoading(false);
    };
    fetchPM();
  }, []);

  return (
    <>
      <Confirm
        isOpen={showConfirm}
        text={`Thank you! Your message has been sent, and we’ll be in touch soon.`}
        confirmText={`CLOSE`}
        onConfirm={() => setShowConfirm(false)}
      />
      <ChangePlanRequest
        isOpen={showChangePlanRequest}
        onClose={() => setShowChangePlanRequest(false)}
        onConfirm={() => {
          setShowChangePlanRequest(false);
          setShowConfirm(true);
        }}
      />
      <div
        className={`${styles.pageBanner} pageBanner`}
        style={{ backgroundImage: `url('${home_banner_logged_in}')` }}
      >
        {console.log("services ", services)}
        <div className={`${styles.container}`}>
          <div className={`${styles.pageBannerContent}`}>
            <h2>Welcome back to the Holistic Management Hub</h2>
            <p>Access and control all Holistic products in one place</p>
          </div>
        </div>
      </div>
      <div className={`${styles.container}`}>
        <div className={`${styles.mainContent}`}>
          <ul className={`${styles.appList}`}>
            <li className={`${styles.qip}`}>
              <span className={`${styles.appName}`}>Holistic QIP</span>
              <span className={`${styles.appDesc}`}>
                Provides you with the direction, tools and help you need to
                successfully manage and complete the QIP/SAT process.
              </span>
              <div className={`${styles.appButtons}`}>
                <a
                  href="https://holisticmanagement.net.au/holistic-qip/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInfoCircle size={17} />
                  LEARN MORE
                </a>
                <a
                  className={`cursor-pointer   ${
                    !services?.some(
                      (service) => service.canAccessQIP || service.isOnTrial
                    )
                      ? styles.notActive
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      !services?.some((service) => service.canAccessQIP) &&
                      services?.some((service) => service.hasSub) &&
                      !hasPm
                    ) {
                      setShowChangePlanRequest(true);
                    } else if (
                      services?.some(
                        (service) => service.canAccessQIP || service.isOnTrial
                      )
                    ) {
                      onRedirect("qip");
                    } else {
                      if (!canAccessQIP && redirectToFailedPayment) {
                        history.push(`/payment/failed`);
                      } else if (
                        !services?.some((service) => service.has_qip)
                      ) {
                        history.push(`/plans`, {
                          action: "change_plan",
                          centerId: services?.find(
                            (service) => service.has_portal
                          )
                            ? services?.filter(
                                (service) => service.has_portal
                              )[0].id
                            : 0,
                          serviceName: services?.find(
                            (service) => service.has_portal
                          )
                            ? services?.filter(
                                (service) => service.has_portal
                              )[0].name
                            : "",
                          productName: services?.find(
                            (service) => service.has_portal
                          )
                            ? services?.filter(
                                (service) =>
                                  service.has_portal && service.hasSub
                              )[0].subscription.product
                            : "",
                          interval: services?.find(
                            (service) => service.has_portal
                          )
                            ? services?.filter(
                                (service) =>
                                  service.has_portal && service.hasSub
                              )[0].subscription.subscription.plan.interval
                            : "",
                        });
                      } else {
                        history.push(`/plans`, {
                          action: "change_plan",
                          centerId: services?.find(
                            (service) => service.has_portal
                          )
                            ? services?.filter(
                                (service) => service.has_portal
                              )[0].id
                            : 0,
                          serviceName: services?.find(
                            (service) => service.has_portal
                          )
                            ? services?.filter(
                                (service) => service.has_portal
                              )[0].name
                            : "",
                          productName: services?.find(
                            (service) => service.has_portal
                          )
                            ? services?.filter(
                                (service) =>
                                  service.has_portal && service.hasSub
                              )[0].subscription.product
                            : "",
                          interval: services?.find(
                            (service) => service.has_portal
                          )
                            ? services?.filter(
                                (service) =>
                                  service.has_portal && service.hasSub
                              )[0].subscription.subscription.plan.interval
                            : "",
                        });
                      }
                    }
                  }}
                >
                  <FaExternalLinkSquareAlt size={17} />
                  {services?.some((service) => service.has_qip)
                    ? "LAUNCH"
                    : services?.some((service) => service.isOnTrial)
                    ? "TRIAL"
                    : "SIGN UP"}
                </a>
              </div>
              <figure className={`${styles.img_wrap}`}>
                <img src={holistic_qip_img} alt="holistic qip banner" />
              </figure>
            </li>
            <li className={`${styles.portal}`}>
              <span className={`${styles.appName}`}>
                Holistic Management Portal
              </span>
              <span className={`${styles.appDesc}`}>
                Provides Leaders, Managers and Educators with instant access to
                exclusive Coaching & Mentoring resources.{" "}
              </span>
              <div className={`${styles.appButtons}`}>
                <a
                  href="https://holisticmanagement.net.au/holistic-management-portal/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInfoCircle size={17} />
                  LEARN MORE
                </a>
                <a
                  className={`cursor-pointer   ${
                    !services?.some(
                      (service) => service.canAccessPortal || service.isOnTrial
                    )
                      ? styles.notActive
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      !services?.some((service) => service.canAccessPortal) &&
                      services?.some((service) => service.hasSub) &&
                      !hasPm
                    ) {
                      setShowChangePlanRequest(true);
                    } else if (
                      services?.some(
                        (service) =>
                          service.canAccessPortal || service.isOnTrial
                      )
                    ) {
                      onRedirect("portal");
                    } else {
                      if (!canAccessPortal && redirectToFailedPayment) {
                        history.push(`/payment/failed`);
                      } else if (
                        !services?.some((service) => service.has_portal)
                      ) {
                        history.push(`/plans`, {
                          action: "change_plan",
                          centerId: services?.find((service) => service.has_qip)
                            ? services?.filter((service) => service.has_qip)[0]
                                .id
                            : 0,
                          serviceName: services?.find(
                            (service) => service.has_qip
                          )
                            ? services?.filter((service) => service.has_qip)[0]
                                .name
                            : "",
                          productName: services?.find(
                            (service) => service.has_qip
                          )
                            ? services?.filter(
                                (service) => service.has_qip && service.hasSub
                              )[0].subscription.product
                            : "",
                          interval: services?.find((service) => service.has_qip)
                            ? services?.filter(
                                (service) => service.has_qip && service.hasSub
                              )[0].subscription.subscription.plan.interval
                            : "",
                        });
                      } else {
                        history.push(`/plans`, {
                          action: "change_plan",
                          centerId: services?.find((service) => service.has_qip)
                            ? services?.filter((service) => service.has_qip)[0]
                                .id
                            : 0,
                          serviceName: services?.find(
                            (service) => service.has_qip
                          )
                            ? services?.filter((service) => service.has_qip)[0]
                                .name
                            : "",
                          productName: services?.find(
                            (service) => service.has_qip
                          )
                            ? services?.filter(
                                (service) => service.has_qip && service.hasSub
                              )[0].subscription.product
                            : "",
                          interval: services?.find((service) => service.has_qip)
                            ? services?.filter(
                                (service) => service.has_qip && service.hasSub
                              )[0].subscription.subscription.plan.interval
                            : "",
                        });
                      }
                    }
                  }}
                >
                  <FaExternalLinkSquareAlt size={17} />
                  {services?.some((service) => service.has_portal)
                    ? "LAUNCH"
                    : services?.some((service) => service.isOnTrial)
                    ? "TRIAL"
                    : "SIGN UP"}
                </a>
              </div>
              <figure className={`${styles.img_wrap}`}>
                <img src={holistic_management_portal} />
              </figure>
            </li>
          </ul>
        </div>
      </div>{" "}
    </>
  );
};

export default HomePage;
