import axios from "../services/axios";
// import axios from "axios";
import { PastPayment } from "../types/pastPayment";

// TODO: use HUB api

const API_URL = process.env.REACT_APP_QIP_API_URL;

export async function get(userId?: number): Promise<PastPayment[]> {
  if (!userId) return [];

  let token: any = localStorage.getItem("hubAppToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.get(
    `${API_URL}/api/stripe/invoices/${userId}`,
    config
  );

  return response?.data || [];
}

export const invoiceApi = {
  get,
};
