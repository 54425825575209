import { useState } from "react";
import styles from "./Details.module.css";
import Card from "../../../components/Form/Card";
import PageBanner from "../PageBanner/PageBanner";
import { useHistory, useLocation } from "react-router-dom";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { getUserLoggedIn, isUserLoggedIn } from "../../../services/auth";
import { FaSpinner } from "react-icons/fa";
import { useEffect } from "react";
import { LANDINGPAGE } from "../../../enum/action";
import { useStoreActions, useStoreState } from "../../../hooks";

const Details = () => {
  // TODO: add state for selected product. redirect to plans when no product state
  const history = useHistory();

  const [showErrors, setShowErrors] = useState(false);
  const [cardName, setCardName] = useState("");
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const location: any = useLocation();

  const authUser = getUserLoggedIn();

  const fetchServicesDetails = useStoreActions(
    (actions) => actions.userStore.fetchServicesDetails
  );

  const apiURI = process.env.REACT_APP_QIP_API_URL;

  const getProductInfo = () => {
    if (location.state !== undefined) {
      const { state } = location;
      switch (state.action) {
        case LANDINGPAGE.new_signup:
          return {
            action: LANDINGPAGE.new_signup,
            name: state.title,
            frequency: state.isMonthly ? "monthly" : "yearly",
            price: state.price,
            price_id: state.priceId,
            serviceName: state.serviceName,
            trialEnded: state.trialEnded,
          };
        case LANDINGPAGE.trial_ending:
          return {
            action: LANDINGPAGE.trial_ending,
            name: state.product.title,
            frequency: state.isMonthly ? "monthly" : "yearly",
            price: state.price,
            price_id: state.priceId,
            serviceName: "",
            trialEnded: state.trialEnded,
          };
        case LANDINGPAGE.trial_ended:
          return {
            action: LANDINGPAGE.trial_ended,
            name: state.product.title,
            frequency: state.isMonthly ? "monthly" : "yearly",
            price: state.price,
            price_id: state.priceId,
            serviceName: "",
            trialEnded: state.trialEnded,
          };
        case LANDINGPAGE.new_service:
          return {
            action: LANDINGPAGE.new_service,
            name: state.product.title,
            frequency: state.isMonthly ? "monthly" : "yearly",
            price: state.price,
            price_id: state.priceId,
            serviceName: "",
            trialEnded: state.trialEnded,
          };
        default:
          return {
            action: "",
            name: state.product.title,
            frequency: state.isMonthly ? "monthly" : "yearly",
            price: state.price,
            price_id: state.priceId,
            serviceName: "",
            trialEnded: state.trialEnded,
          };
      }
    } else {
      return {
        action: "",
        name: "",
        frequency: "",
        price: "",
        price_id: "",
        serviceName: "",
        trialEnded: false,
      };
    }
  };

  const onPayNow = async () => {
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);

    if (!card) return;

    setLoading(true);
    const stripePayload = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        name: cardName,
        email: authUser.email,
      },
    });

    const payload = {
      email: authUser.email,
      price: getProductInfo().price_id,
      plan: getProductInfo().name,
      interval: getProductInfo().frequency,
      payment_method_id: stripePayload.paymentMethod?.id,
    };

    try {
      const response = await fetch(`${apiURI}/api/subscribe`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      await fetchServicesDetails(authUser.id);
      history.push(`/landingpage`, getProductInfo());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state === undefined) {
      history.push(`/plans`);
    }
  }, [location]);

  return (
    <>
      <PageBanner title="Payment" />
      <div className={styles.container}>
        <div
          className={`${styles.mainContent} grid md:grid-cols-1 lg:grid-cols-2 gap-10`}
        >
          <div>
            <h2>Finish payment</h2>
            {/* TODO DYNAMIC TEXT DEPENDING ON PRODUCT STATE */}
            <p className={styles.description}>
              {/* Complete your subscription to your Holistic QIP $97 monthly plan
              by providing your credit card details. */}
              {`Complete your subscription to your ${getProductInfo()?.name} $${
                getProductInfo().price
              } ${getProductInfo().frequency} plan
              by providing your credit card details.`}
            </p>
          </div>
          <div>
            <Card
              cardName={cardName}
              setCardName={setCardName}
              showErrors={showErrors}
            />
            <div className="mt-5">
              <button
                disabled={loading}
                className={styles.payBtn}
                onClick={() => {
                  setShowErrors(!showErrors);
                  onPayNow();
                }}
              >
                {loading ? (
                  <div className={`flex justify-center items-center`}>
                    <FaSpinner className={`animate-spin text-white`} />{" "}
                    <span className={`ml-2`}>Please wait...</span>{" "}
                  </div>
                ) : (
                  `PAY NOW`
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
