export const PRODUCT_KEYS = {
  qip: "qip",
  portal: "portal",
  portalAndQip: "portalAndQip",
  fullManagement: "fullManagement",
};

const priceIds = (id: number): any => {
  switch (process.env.REACT_APP_PRICEID) {
    case "TEST":
      switch (id) {
        case 1:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1ISv8MDeO3XG77wNfKE2mu9G",
                yearly: "price_1ISv8XDeO3XG77wNAPhSKSsG",
              },
              withNoTrial: {
                monthly: "price_1HjdWJDeO3XG77wN2ZWtoJzb",
                yearly: "price_1Je9nQDeO3XG77wNUENKkNku",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1ISv7HDeO3XG77wNIKR5IAk3",
                yearly: "price_1ISv7XDeO3XG77wNZHPAFVGy",
              },
              withNoTrial: {
                monthly: "price_1HjdVfDeO3XG77wNweTXFOcB",
                yearly: "price_1HjdVfDeO3XG77wNA9yXwT1m",
              },
            },
          };

        case 2:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1Je8giDeO3XG77wNBcdVvY36",
                yearly: "price_1Je8giDeO3XG77wNHqVRZctE",
              },
              withNoTrial: {
                monthly: "price_1Je8giDeO3XG77wNGOUjukdy",
                yearly: "price_1Je8giDeO3XG77wNbKQ0QJi6",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1Je8eqDeO3XG77wNNhU7Kkgz",
                yearly: "price_1Je8eqDeO3XG77wNMUrEtNZh",
              },
              withNoTrial: {
                monthly: "price_1Je8eqDeO3XG77wNUyTyiWJE",
                yearly: "price_1Je8eqDeO3XG77wNLAJuQZdN",
              },
            },
          };
        case 3:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1Je9ljDeO3XG77wN3Fwcc8xi",
                yearly: "price_1Je9ljDeO3XG77wNkCiEIbSu",
              },
              withNoTrial: {
                monthly: "price_1Je9ljDeO3XG77wNZU8IjcDy",
                yearly: "price_1Je9ljDeO3XG77wNzrowg6XJ",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1Je9jLDeO3XG77wNA2L2k9iw",
                yearly: "price_1Je9jLDeO3XG77wNX6uyKkNN",
              },
              withNoTrial: {
                monthly: "price_1Je9jLDeO3XG77wNvasJuWzQ",
                yearly: "price_1Je9jLDeO3XG77wNIVzhVRi4",
              },
            },
          };
        case 4:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1Je9ljDeO3XG77wN3Fwcc8xi",
                yearly: "price_1Je9ljDeO3XG77wNkCiEIbSu",
              },
              withNoTrial: {
                monthly: "price_1Je9ljDeO3XG77wNZU8IjcDy",
                yearly: "price_1Je9ljDeO3XG77wNzrowg6XJ",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1Je9jLDeO3XG77wNA2L2k9iw",
                yearly: "price_1Je9jLDeO3XG77wNX6uyKkNN",
              },
              withNoTrial: {
                monthly: "price_1Je9jLDeO3XG77wNvasJuWzQ",
                yearly: "price_1Je9jLDeO3XG77wNIVzhVRi4",
              },
            },
          };
      }
      break;
    case "LIVE":
      switch (id) {
        case 1:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1ISmByDeO3XG77wNxXqNbNWM",
                yearly: "price_1ISmCKDeO3XG77wNbv89lSUH",
              },
              withNoTrial: {
                monthly: "price_1IOWb0DeO3XG77wNEhS9fx7l",
                yearly: "price_1IOWb0DeO3XG77wN3N58kPjh",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1ISmAZDeO3XG77wNRRCmGou1",
                yearly: "price_1ISmBJDeO3XG77wNESEFUbyJ",
              },
              withNoTrial: {
                monthly: "price_1IOWbPDeO3XG77wNEHMAsNwB",
                yearly: "price_1IOWbPDeO3XG77wNBFPuWdQR",
              },
            },
          };
        case 2:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1Js29ZDeO3XG77wNXsQ7eG1I",
                yearly: "price_1Js29ZDeO3XG77wNvXsMfMI2",
              },
              withNoTrial: {
                monthly: "price_1Js29ZDeO3XG77wNAy1uRmrR",
                yearly: "price_1Js29ZDeO3XG77wNxoLECEMT",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1Js2AWDeO3XG77wNL7wfZraE",
                yearly: "price_1Js2AVDeO3XG77wNt1D2af06",
              },
              withNoTrial: {
                monthly: "price_1Js2AWDeO3XG77wNEUCRDJvw",
                yearly: "price_1Js2AVDeO3XG77wN9yJ72jGI",
              },
            },
          };
        case 3:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1Js279DeO3XG77wN5FOcYcHc",
                yearly: "price_1Js279DeO3XG77wNG6vlBF2N",
              },
              withNoTrial: {
                monthly: "price_1Js279DeO3XG77wNZb0f9A8f",
                yearly: "price_1Js279DeO3XG77wNAcxg6LOb",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1Js28ODeO3XG77wNl9BsLklo",
                yearly: "price_1Js28ODeO3XG77wNAAbNFRyG",
              },
              withNoTrial: {
                monthly: "price_1Js28PDeO3XG77wNl2illxSF",
                yearly: "price_1Js28ODeO3XG77wNL3gOVFVC",
              },
            },
          };
        case 4:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1Js279DeO3XG77wN5FOcYcHc",
                yearly: "price_1Js279DeO3XG77wNG6vlBF2N",
              },
              withNoTrial: {
                monthly: "price_1Js279DeO3XG77wNZb0f9A8f",
                yearly: "price_1Js279DeO3XG77wNAcxg6LOb",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1Js28ODeO3XG77wNl9BsLklo",
                yearly: "price_1Js28ODeO3XG77wNAAbNFRyG",
              },
              withNoTrial: {
                monthly: "price_1Js28PDeO3XG77wNl2illxSF",
                yearly: "price_1Js28ODeO3XG77wNL3gOVFVC",
              },
            },
          };
      }
      break;
    default:
      switch (id) {
        case 1:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1ISv8MDeO3XG77wNfKE2mu9G",
                yearly: "price_1ISv8XDeO3XG77wNAPhSKSsG",
              },
              withNoTrial: {
                monthly: "price_1HjdWJDeO3XG77wN2ZWtoJzb",
                yearly: "price_1Je9nQDeO3XG77wNUENKkNku",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1ISv7HDeO3XG77wNIKR5IAk3",
                yearly: "price_1ISv7XDeO3XG77wNZHPAFVGy",
              },
              withNoTrial: {
                monthly: "price_1HjdVfDeO3XG77wNweTXFOcB",
                yearly: "price_1HjdVfDeO3XG77wNA9yXwT1m",
              },
            },
          };
        case 2:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1Je8giDeO3XG77wNBcdVvY36",
                yearly: "price_1Je8giDeO3XG77wNHqVRZctE",
              },
              withNoTrial: {
                monthly: "price_1Je8giDeO3XG77wNGOUjukdy",
                yearly: "price_1Je8giDeO3XG77wNbKQ0QJi6",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1Je8eqDeO3XG77wNNhU7Kkgz",
                yearly: "price_1Je8eqDeO3XG77wNMUrEtNZh",
              },
              withNoTrial: {
                monthly: "price_1Je8eqDeO3XG77wNUyTyiWJE",
                yearly: "price_1Je8eqDeO3XG77wNLAJuQZdN",
              },
            },
          };
        case 3:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1Je9ljDeO3XG77wN3Fwcc8xi",
                yearly: "price_1Je9ljDeO3XG77wNkCiEIbSu",
              },
              withNoTrial: {
                monthly: "price_1Je9ljDeO3XG77wNZU8IjcDy",
                yearly: "price_1Je9ljDeO3XG77wNzrowg6XJ",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1Je9jLDeO3XG77wNA2L2k9iw",
                yearly: "price_1Je9jLDeO3XG77wNX6uyKkNN",
              },
              withNoTrial: {
                monthly: "price_1Je9jLDeO3XG77wNvasJuWzQ",
                yearly: "price_1Je9jLDeO3XG77wNIVzhVRi4",
              },
            },
          };
        case 4:
          return {
            withMentoring: {
              withTrial: {
                monthly: "price_1Je9ljDeO3XG77wN3Fwcc8xi",
                yearly: "price_1Je9ljDeO3XG77wNkCiEIbSu",
              },
              withNoTrial: {
                monthly: "price_1Je9ljDeO3XG77wNZU8IjcDy",
                yearly: "price_1Je9ljDeO3XG77wNzrowg6XJ",
              },
            },
            withNoMentoring: {
              withTrial: {
                monthly: "price_1Je9jLDeO3XG77wNA2L2k9iw",
                yearly: "price_1Je9jLDeO3XG77wNX6uyKkNN",
              },
              withNoTrial: {
                monthly: "price_1Je9jLDeO3XG77wNvasJuWzQ",
                yearly: "price_1Je9jLDeO3XG77wNIVzhVRi4",
              },
            },
          };
      }
  }
};

export const PRODUCTS = [
  {
    id: 1,
    title: "Holistic QIP",
    label: "Holistic QIP",
    alias: "qip",
    monthly: 97,
    yearly: 997,
    key: PRODUCT_KEYS.qip,
    price_ids: priceIds(1),
  },
  {
    id: 2,
    title: "HMC Portal",
    label: "Holistic Management Portal",
    alias: "portal",
    monthly: 97,
    yearly: 997,
    key: PRODUCT_KEYS.portal,
    price_ids: priceIds(2),
  },
  {
    id: 3,
    title: "Holistic QIP & HMC Portal",
    label: "Holistic Management Portal & QIP",
    alias: "qipportal",
    monthly: 194,
    yearly: 1994,
    key: PRODUCT_KEYS.portalAndQip,
    price_ids: priceIds(3),
  },
  {
    id: 4,
    title: "Full management",
    label: "Full management",
    alias: "full",
    monthly: 997,
    yearly: 9970,
    key: PRODUCT_KEYS.fullManagement,
    price_ids: priceIds(4),
  },
];

type PricingInfo = {
  title: string;
  tooltip?: string;
  features: { [key: string]: boolean };
};
export const PRICING_INFO: PricingInfo[] = [
  {
    title: "Manage your QIP/SAT",
    tooltip:
      "Holistic QIP is an online software that gives you an organised, focused and stress-free approach to meeting your legal obligations, whilst showcasing the quality of your service in a fraction of the time.",
    features: {
      qip: true,
      portal: false,
      portalAndQip: true,
      fullManagement: true,
    },
  },
  {
    title: "Policies & Procedures",
    tooltip:
      "Resources for all National Quality Areas; tools, templates, forms, policies and procedures",
    features: {
      qip: false,
      portal: true,
      portalAndQip: true,
      fullManagement: true,
    },
  },
  {
    title: "Resources Library",
    tooltip:
      "A complete online library with instant access for all users.  The HMC online library has been designed and continuously updated for the last 15 years with regular updates occurring to support services achieve great outcomes and great A&R results. Over 500 Forms, Tools and Templates to access and utilise for all management, leadership and exceeding practices.",
    features: {
      qip: true,
      portal: true,
      portalAndQip: true,
      fullManagement: true,
    },
  },
  {
    title: "Access to Support",
    tooltip:
      "We offer the most comprehensive mentoring program in the country. With instant access to our resources, training modules, Focus of The Month guidelines and our experienced team of mentors and coaches led by leadership specialist and strategist Alina Dan.  We can support you and your team across all areas of operation, development and leadership via online contact, centre mentoring visits, audits, phone & email support. Access the team weekdays from 9am - 5pm",
    features: {
      qip: true,
      portal: true,
      portalAndQip: true,
      fullManagement: true,
    },
  },
  {
    title: "Focus of The Month Program",
    tooltip:
      "Our most effective leadership tool – Focus of the Month has been designed for all 3 main management layers (NS, EL and Lead Educators) to motivate, guide and increase critical reflection impact amongst the team. It is a great reference for building and strengthening team culture and achievement of great results.",
    features: {
      qip: false,
      portal: true,
      portalAndQip: true,
      fullManagement: true,
    },
  },
  {
    title: "Video & Audio Webinars",
    tooltip:
      "One of our most sought-after features is the Monthly Webinars that we upload and share with all services. All webinars are recorded and available for services to access at their own discretion.",
    features: {
      qip: false,
      portal: true,
      portalAndQip: true,
      fullManagement: true,
    },
  },
  {
    title: "All Manuals and Diaries",
    tooltip:
      "HCM includes the most comprehensive index of resources in the country. Our mentoring services receive instant access to the Director & EL Manuals, over 5 other books (Displays, Critical Reflection, Documentation, School Readiness, STEM) and yearly diaries.",
    features: {
      qip: false,
      portal: false,
      portalAndQip: true,
      fullManagement: true,
    },
  },
  {
    title: "Online Monthly Check In Sessions",
    tooltip:
      "We work hard to support and organise all Monthly Check ins at a time that suits the service and their team. It is a simple process and almost immediately confirmed.",
    features: {
      qip: false,
      portal: false,
      portalAndQip: true,
      fullManagement: true,
    },
  },
  {
    title: "PD Termly",
    tooltip:
      "For all our full mentoring program subscribers an in-house Professional Development (PD) session is made available every term. (Limitations may occur during lockdowns). This is a wonderful feature that strongly supports and propels teams into achieving depth of knowledge and skills.",
    features: {
      qip: false,
      portal: false,
      portalAndQip: false,
      fullManagement: true,
    },
  },
  {
    title: "Educator’s Conference",
    tooltip:
      "For our full mentoring program subscribers, we organise and present at a 1 full day conference/year. This is a great opportunity to come together and connect at a professional and pedagogical way as well as access the resources and knowledge of all HMC mentors/specialists.",
    features: {
      qip: false,
      portal: false,
      portalAndQip: false,
      fullManagement: true,
    },
  },
  {
    title: "HR Support & Development",
    tooltip:
      "Part of our HMC team of specialist we have a HR consultant that guides all leaders through staffing arrangements queries and challenges. It is a great resource for services to access and be mentored by. ",
    features: {
      qip: false,
      portal: false,
      portalAndQip: false,
      fullManagement: true,
    },
  },
  {
    title: "Strategic Planning",
    tooltip:
      "Our lead coach and strategist Alina Dan is renowned for her Strategic Planning skills, knowledge, and influence on hundreds of early childhood leaders within the sector. Her pragmatic yet highly effective approach enables all leaders to become confident plan developers, skilled analysts and reflective leaders.  All subscribers have instant access to various strategic planning tools and overviews. ",
    features: {
      qip: false,
      portal: false,
      portalAndQip: false,
      fullManagement: true,
    },
  },
  {
    title: "Yearly Auditing & A&R Prep",
    tooltip:
      "All our full mentoring program subscribers take part in an assiduous A&R preparation process and auditing. It is the most comprehensive auditing process designed to identify any possible gaps across all 40 elements and all 3 Exceeding Themes.",
    features: {
      qip: false,
      portal: false,
      portalAndQip: false,
      fullManagement: true,
    },
  },
];
