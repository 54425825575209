import { isEmpty } from "lodash";
import { recordAppUsage } from "../api/request";
import { RoutingScheme } from "../facades/RoutingScheme";
import { getUserLoggedIn, getHubViewer } from "../services/auth";
import generateRandom from "../utils/generateRandom";

export const redirect = (
  app: string,
  userId: number,
  centerId: number,
  stripeId?: string
) => {
  const hostname = new URL(window.location.href).hostname.split(".");

  let token = localStorage.getItem("hubAppToken") || "";

  let authUser = getUserLoggedIn();

  let appURL = process.env.REACT_APP_QIP_URL;

  if (app === "qip") {
    appURL = process.env.REACT_APP_QIP_URL;
  }

  if (app === "portal") {
    appURL = process.env.REACT_APP_PORTAL_URL;
  }

  const routingScheme = new RoutingScheme(token);

  const cipherToken = routingScheme
    .generateIV(true)
    .generateSalt(true)
    .generateCipher();

  const routingScheme1 = new RoutingScheme(JSON.stringify(authUser));

  const cipherAuth = routingScheme1
    .generateIV(true)
    .generateSalt(true)
    .generateCipher();

  const TokenHash = routingScheme.generateSignature(token);

  const AuthHash = routingScheme.generateSignature(JSON.stringify(authUser));

  const counter = generateRandom(12);

  const onRecordAppUsage = async () => {
    await recordAppUsage(userId, centerId, app);
  };

  if (isEmpty(getHubViewer())) {
    onRecordAppUsage();
  }

  if (hostname[0] === "localhost") {
    window.open(
      "http://localhost:3001/hub-app-launch-redirect?userId=" +
        userId +
        "&centerId=" +
        centerId +
        "&stripeId=" +
        (stripeId ? stripeId : "empty") +
        "&accessToken=" +
        encodeURIComponent(cipherToken) +
        "&tokenHash=" +
        encodeURIComponent(TokenHash) +
        "&auth=" +
        encodeURIComponent(cipherAuth) +
        "&authHash=" +
        encodeURIComponent(AuthHash) +
        "&counter=" +
        counter
    );
  } else {
    // const host = hostname.slice(1, hostname.length).join(".");
    window.open(
      appURL +
        "hub-app-launch-redirect?userId=" +
        userId +
        "&centerId=" +
        centerId +
        "&stripeId=" +
        (stripeId ? stripeId : "empty") +
        "&accessToken=" +
        encodeURIComponent(cipherToken) +
        "&tokenHash=" +
        encodeURIComponent(TokenHash) +
        "&auth=" +
        encodeURIComponent(cipherAuth) +
        "&authHash=" +
        encodeURIComponent(AuthHash) +
        "&counter=" +
        counter
    );
  }
};
