import axios from "../services/axios";
import { AxiosError } from "axios";
import {
  ILoginCredentials,
  NewServicePayload,
  SignUpData,
  SignUpResponse,
  NotificationPayload,
} from "../types";
import { IAuthUser } from "../types";

export async function login(loginPayload: ILoginCredentials) {
  const payload = {
    username: loginPayload.username,
    password: loginPayload.password,
    grant_type: "password",
    client_id: 8,
    client_secret: "LrCrm649CSz0RWPr3sdCwkxm337o09TXTwesqgBE",
  };
  try {
    const response = await axios.post(`oauth/token`, payload);

    let config = {
      headers: {
        Authorization: "Bearer " + response.data.access_token,
      },
    };

    const response2 = await axios.get("/api/user", config);

    return {
      status: true,
      access_token: response.data.access_token,
      auth_user: response2.data,
    };
  } catch (error: any) {
    return {
      status: false,
      ...error.toJSON(),
    };
  }
}

export async function getTokenStatus(accessToken: string): Promise<boolean> {
  let token: any = localStorage.getItem("hubAppToken");

  let config = {
    headers: {
      Authorization: "Bearer " + (token || accessToken),
    },
  };

  try {
    const response = await axios.get("/api/auth-check", config);
    console.log("token status on reply ", response.data);
    return response.data.valid;
  } catch (error) {
    console.error(error);
    console.log("token status on error ", false);
    return false;
  }
}

export async function getUserInfo(access_token: string): Promise<IAuthUser> {
  try {
    let config = {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    };

    const response = await axios.get("/api/user", config);

    return response?.data;
  } catch (error: any) {
    return {
      status: false,
      ...error.toJSON(),
    };
  }
}

export async function sendResetPassword(email: string) {
  let token: any = localStorage.getItem("hubAppToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const payload = {
    email,
  };

  try {
    const response = await axios.post(`/api/lost-password`, payload, config);

    return response.status === 200 || response.status === 201 ? true : false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function createNewPassword(
  userId: number,
  token: string,
  newPassword: string
) {
  const payload = {
    token,
    password: newPassword,
  };

  let token1: any = localStorage.getItem("hubAppToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token1,
    },
  };

  try {
    const response = await axios.post(`/api/new-password`, payload, config);

    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getUserDataServices(
  userId: number,
  accessToken?: String
) {
  let token: any = localStorage.getItem("hubAppToken");

  let config = {
    headers: {
      Authorization: "Bearer " + (token || accessToken),
    },
  };

  const API_URL = process.env.REACT_APP_QIP_API_URL;

  try {
    const response = await getTokenStatus(token || accessToken);

    if (!response) {
      localStorage.clear();
      window.location.reload();
    }

    const res = await axios.get(
      `${API_URL}/api/user/${userId}/centers/subscription`,
      config
    );

    const centers = res.data.user_centers.original;

    const centersData = res.data.user_details.original;

    const subData = res.data.subscription.original;

    return {
      status: true,
      centers: centers || [],
      centersData: centersData || [],
      subData: subData || [],
    };
  } catch (error) {
    console.error(error);
    return {
      status: false,
      centers: [],
      centersData: [],
      subData: [],
    };
  }
}

export async function changePlan(
  userId: number,
  centerId: number,
  price: string
) {
  const API_URL = process.env.REACT_APP_QIP_API_URL;

  const changePlanPayload = {
    user_id: userId,
    center_id: centerId,
    price,
  };

  let token: any = localStorage.getItem("hubAppToken");
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.post(
    `${API_URL}/api/changeplan`,
    changePlanPayload,
    config
  );

  return response.data;
}

export async function createNewService(nspayload: NewServicePayload) {
  const payload = {
    user_id: nspayload.user_id,
    service_name: nspayload.service_name,
    service_app_num: nspayload.service_app_num,
    service_cat: nspayload.service_cat,
    center_user_type: nspayload.center_user_type,
    name: nspayload.name,
    email: nspayload.email,
    phone: nspayload.phone,
    created_at: nspayload.created_at,
    price: nspayload.price,
    plan: nspayload.plan,
    payment_method_id: nspayload.payment_method_id,
  };

  let token: any = localStorage.getItem("hubAppToken");
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.post(`/api/create-service`, payload, config);

  console.log("response ns", response);
}

export async function registerUser(data: SignUpData) {
  const API_URL = process.env.REACT_APP_QIP_API_URL;

  const payload = {
    price: data.price,
    name: `${data.firstName} ${data.lastName}`,
    email: data.email,
    phone: data.phoneNumber,
    password: data.password,
    user_type: "ADMIN",
    service_name: data.serviceName,
    service_app_num: data.serviceApprovalNumber
      ? data.serviceApprovalNumber
      : "#",
    service_cat: data.serviceCategory,
    center_user_type: "admin",
    preferred_date: data.preferredDate,
    preferred_time: data.preferredTime,
    has_qip: data.has_qip,
    has_portal: data.has_portal,
  };

  try {
    const response = await axios.post(`/api/register`, payload);

    let config = {
      headers: {
        Authorization: "Bearer " + response.data.accessToken,
      },
    };

    const response2 = await axios.get(`/api/user`, config);

    return {
      status: true,
      access_token: response.data.accessToken,
      auth_user: response2.data,
    };
  } catch (error: any) {
    return {
      status: false,
      ...error.toJSON(),
    };
  }
}

export async function fullManagementNotification(
  notification: NotificationPayload
) {
  let token: any = localStorage.getItem("hubAppToken");
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const payload = {
    name: notification.name,
    email: notification.email,
    phone_number: notification.phone_number,
    service_name: notification.service_name,
    comments: notification.comments,
    daysoftheweek: notification.daysoftheweek,
    timeofday: notification.timeofday,
  };

  const response = await axios.post(
    `/api/full-management-notification`,
    payload,
    config
  );
}

export async function changePlanNotification(
  notification: NotificationPayload
) {
  let token: any = localStorage.getItem("hubAppToken");
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const payload = {
    name: notification.name,
    email: notification.email,
    phone_number: notification.phone_number,
    service_name: notification.service_name,
    comments: notification.comments,
    daysoftheweek: notification.daysoftheweek,
    timeofday: notification.timeofday,
  };

  const response = await axios.post(
    `/api/change-plan-notification`,
    payload,
    config
  );
}

export async function addServiceRequestNotification(
  notification: NotificationPayload
) {
  let token: any = localStorage.getItem("hubAppToken");
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const payload = {
    name: notification.name,
    email: notification.email,
    phone_number: notification.phone_number,
    service_name: notification.service_name,
    new_service_name: notification.new_service_name,
    service_app_num: notification.service_app_num,
    service_type: notification.service_type,
    comments: notification.comments,
    daysoftheweek: notification.daysoftheweek,
    timeofday: notification.timeofday,
  };

  const response = await axios.post(
    `/api/add-service-request-notification`,
    payload,
    config
  );
}

export async function recordAppUsage(
  userId: number,
  centerId: number,
  redirectTo: string
) {
  const API_URL = process.env.REACT_APP_QIP_API_URL;

  let token: any = localStorage.getItem("hubAppToken");
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.get(`${API_URL}/api/user/record/redirect`, {
    params: {
      user_id: userId,
      redirect_to: redirectTo,
    },
    headers: config.headers,
  });
}
